// @ts-nocheck
import { FunctionComponent, useState } from 'react';

//API
import DefaultClientOrgChannelApiClient from '../../../apiClient/DefaultClientOrgChannelApiClient';

// components
import { Button } from '@raptormaps/button';
import { Typeahead } from 'react-bootstrap-typeahead';

//styled components
import { ErrorText } from '../styles/DefaultClientOrg_styles';
import { Row, Stack } from '@raptormaps/layout';

interface IAddNewOrg {
  currentOrgChannelList?: any
  orgOptions: any;
  channelOptions: any;
  handleFetchOrgChannel: any;
}

const AddNewOrg: FunctionComponent<IAddNewOrg> = ({
  currentOrgChannelList,
  orgOptions,
  channelOptions,
  handleFetchOrgChannel
}) => {
  const [newOrg, setNewOrg] = useState(null);
  const [newChannel, setNewChannel] = useState(null);
  const [error, setError] = useState(null);

  const handlePostNewOrgChannel = () => {
    let checkCurrentOrgs = currentOrgChannelList.find(item => item.id === newOrg[0]?.id);

    let newOrgObj = {
      org_id: newOrg[0].id,
      channel_id: newChannel[0].id
    };
    if (checkCurrentOrgs !== undefined) {
      return handleShowError();
    } else {
      let orgApi = new DefaultClientOrgChannelApiClient();
      orgApi.postBPOOrgChannel(newOrgObj).then(res => {
        handleFetchOrgChannel();
      });
    }
  };

  const handleShowError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2000);
  };

  return (
    <Stack gutter="lg" align="start" style={{marginBottom: 40}}>
      <Row gutter="sm">
        <Typeahead
          id="org_id"
          name="org_id"
          className="org_id"
          placeholder="Search Client Org..."
          defaultInputValue={''}
          options={orgOptions}
          maxResults={10}
          labelKey={o => o?.name}
          onBlur={() => {}}
          onChange={item => setNewOrg(item)}
        />

        <Typeahead
          id="channel_id"
          name="channel_id"
          className="channel_id"
          placeholder="Search Channels..."
          defaultInputValue={''}
          options={channelOptions}
          maxResults={10}
          labelKey={o => o?.name}
          onBlur={() => {}}
          onChange={item => setNewChannel(item)}
        />
      </Row>
      {error && <ErrorText>The organization already exists</ErrorText>}
      <Button onClick={() => handlePostNewOrgChannel()}>
        Save
      </Button>
    </Stack>
  );
};

export default AddNewOrg;
