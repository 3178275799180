import React, { Component } from "react";
import toastr from "toastr";
import {
  Col,
  Row,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLink,
  faArrowRight,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import DataRequestForm from "./DataRequestForm";
import RestApiV2 from "../api/RestApiV2";

/**
 *
 *
 * @class RequestDataPage - Lets user with raptoradmin make a request for data upload
 * @extends {Component}
 */
class RequestDataPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadRequests: [],
      totalCount: 0,
      tableSize: 10,
      page: 0,
      showModal: false,
    };
    this.api = new RestApiV2();
  }

  componentDidMount() {
    this.getUploadRequests(this.state.tableSize);
  }

  createUploadRequest = (params) => {
    return new Promise((resolve, reject) => {
      this.api
        .post("/raptor_admin_create_upload_request", params)
        .then((ret) => {
          let reqList = [ret.upload_request];
          this.setState((prevState) => ({
            uploadRequests: reqList.concat(prevState.uploadRequests),
            totalCount: prevState.totalCount + 1,
          }));
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  getUploadRequests = (n, offset = 0) => {
    this.api
      .get(`/raptor_admin_upload_requests?n_requests=${n}&offset=${offset}`)
      .then((ret) => {
        this.setState((prevState) => {
          let requests = ret.upload_requests;
          let count = ret.count;
          prevState.uploadRequests.map((req) => {
            if (!requests.find((r) => r.id === req.id)) {
              requests.push(req);
            }
          });
          requests.sort((a, b) => b.created_tsecs - a.created_tsecs);
          return {
            uploadRequests: requests,
            totalCount: count,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Failed to get upload requests");
      });
  };

  toggleAccessToken = (uploadRequestId) => {
    this.api
      .post(`raptor_admin_upload_requests/${uploadRequestId}/toggle_active`)
      .then((ret) => {
        this.setState((prevState) => {
          let requests = [];
          prevState.uploadRequests.map((req) => {
            if (req.id === ret.upload_request.id) {
              requests.push(ret.upload_request);
            } else {
              requests.push(req);
            }
          });
          requests.sort((a, b) => b.created_tsecs - a.created_tsecs);
          return {
            uploadRequests: requests,
          };
        });
      })
      .catch((err) => {
        toastr.error("Failed to toggle access lock");
      });
  };

  getMoreRequestsIfNecessary = () => {
    const count = this.state.uploadRequests.length;
    if (count < (this.state.page + 1) * this.state.tableSize) {
      this.getUploadRequests(
        this.state.tableSize,
        this.state.page * this.state.tableSize
      );
    }
  };

  setTableSize = (size) => {
    this.setState(
      {
        tableSize: size,
        page: 0,
      },
      this.getMoreRequestsIfNecessary
    );
  };

  pageUp = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
      }),
      this.getMoreRequestsIfNecessary
    );
  };

  pageDown = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page - 1,
      }),
      this.getMoreRequestsIfNecessary
    );
  };

  showNewRequestModal = () => {
    this.setState({
      showModal: true,
    });
  };

  closeNewRequestModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const timeFormatter = (cell, row) => {
      let d = new Date(0);
      d.setUTCSeconds(cell);
      return d.toLocaleString();
    };

    const uploadRequestColumns = [
      {
        dataField: "id",
        text: "ID",
        sort: true,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "creator.email",
        text: "Requester",
        sort: true,
      },
      {
        dataField: "org.name",
        text: "Dest Org",
        sort: true,
      },
      {
        dataField: "upload_sessions.length",
        text: "Uploads",
        sort: false,
      },
      {
        dataField: "created_tsecs",
        text: "Created",
        sort: true,
        formatter: timeFormatter,
      },
      {
        dataField: "get_link",
        isDummyField: true,
        text: "Link",
        formatter: (cell, row) => {
          return (
            <CopyToClipboard text={row.url.replace("api.", "app.")}>
              <Button title={row.url.replace("api.", "app.")}>
                <FontAwesomeIcon icon={faLink} />
              </Button>
            </CopyToClipboard>
          );
        },
      },
      {
        dataField: "toggle_access",
        isDummyField: true,
        text: "Access",
        formatter: (cell, row) => {
          let style = row.access_token.is_active ? "success" : "danger";
          let title = row.access_token.is_active
            ? "Locking will prevent further uploads from anyone who has this link"
            : "Unlocking will allow anyone with this link to upload to the destination org";

          return (
            <Button
              onClick={() => this.toggleAccessToken(row.id)}
              variant={style}
              title={title}
            >
              <FontAwesomeIcon icon={faLock} />
            </Button>
          );
        },
      },
    ];
    const start = this.state.page * this.state.tableSize;
    let filteredRequests = this.state.uploadRequests.slice(
      start,
      start + this.state.tableSize
    );

    return (
      <div className="raptor-admin-view">
        <Row>
          <Col xs={12}>
            <ButtonToolbar>
              <ButtonGroup>
                <Button onClick={this.pageDown} disabled={this.state.page <= 0}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <Button
                  onClick={this.pageUp}
                  disabled={
                    this.state.page + 1 >=
                    this.state.totalCount / this.state.tableSize
                  }
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                {[10, 25, 100].map((size, key) => {
                  return (
                    <Button onClick={() => this.setTableSize(size)} key={key}>
                      {size}
                    </Button>
                  );
                })}
              </ButtonGroup>
              <ButtonToolbar>
                <Button variant="success" onClick={this.showNewRequestModal}>
                  New Data Request
                </Button>
              </ButtonToolbar>
            </ButtonToolbar>

            <BootstrapTable
              keyField="id"
              id="upload-request-table"
              data={filteredRequests}
              columns={uploadRequestColumns}
            />
          </Col>
        </Row>
        {this.state.showModal && (
          <Modal show={this.state.showModal} onHide={this.closeNewRequestModal}>
            <Modal.Header closeButton>
              <Modal.Title>New Data Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DataRequestForm
                orgs={this.props.orgs}
                createUploadRequest={this.createUploadRequest}
                closeModal={this.closeNewRequestModal}
              />
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

export default RequestDataPage;
