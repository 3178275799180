import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { OrderActionCreator } from '../../actions/orderActions';
import { Tab, Tabs } from 'react-bootstrap';
import {
  orderTableTabs,
  orderStatuses,
  tableTabStatuses
} from './util/constants';
import './OrderStatus.css';
import MultiStatusTable from './MultistatusTable';
import LoadMoreOrderTable from './LoadMoreOrderTable';
import OrderTableSearch from './OrderTableSearch';
import { useApi } from '../../hooks/useApi';
import { useDispatch } from 'react-redux';


import { SolarOrdersApi, OrderSummaryToJSON } from '@raptormaps/raptor-internal-client-ts';



//constants
import { POLLING_INTERVAL, LOAD_MORE_INCREMENTER } from '../../constants/index';

const OrderStatus = ({ orders, orderActions, orgs }) => {

  // Local States
  const [isPolling, setIsPolling] = useState(true);
  const [tableFilter, setTableFilter] = useState(null);
  const [activeTab, setActiveTab] = useState(orderTableTabs.ONGOING)
  const [ordersData, setOrdersData] = useState([]);

  // Global States
  const itsFiltered = useSelector(state => state.order.filtered)
  const dispatch = useDispatch();


  const ordersApi = useApi(SolarOrdersApi, {
    basePath: window.REACT_APP_API_ENDPOINT_V2
  })


  useEffect(() => {
    orderActions.getAllInstructions()
  }, []);

  // switch between raw sorted orders or filtered sorted orders
  useEffect(() => {
    if (orders?.length > 0) {
      const sortedOrders = getSortedOrders(orders);
      setOrdersData(sortedOrders);
    }
  }, [orders]);

  useEffect(() => {
    if (isPolling) {
      fetchOrdersForActiveTab(activeTab, itsFiltered)
    }
    // Actively fetch orders every 50 seconds for on going orders
    const interval = setInterval(() => {
      if (isPolling && activeTab === orderTableTabs.ONGOING) {
        ordersApi.getOrderSummaries({statuses: tableTabStatuses[orderTableTabs.ONGOING].join(',')})
        .then(response => {
          let orders = response.orders
          orders = orders.map(order => OrderSummaryToJSON(order))
          dispatch(OrderActionCreator.getAllOrdersSuccess(orders))          
        })
        .catch(() => dispatch(OrderActionCreator.getAllOrdersFailure()))
      }
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [isPolling, activeTab]);

  /**
   * Fetches visible order count for a specific status
   */
  const getVisibleOrdersForStatus = status => {
    const visibleOrders = setOrdersData[status] || [];
    return visibleOrders
  }
  /**
   * Partially fetches orders for views that do not load all orders at once.
   */
  const fetchLoadMoreOrders = tabKey => {
    const statuses = tableTabStatuses[tabKey];
    if (statuses && statuses.length === 1) {
      const status = statuses[0];
      const visibleOrders = getVisibleOrdersForStatus(status);
      
      let ordersToFetchCount;
      
      if (visibleOrders.length === 0) {
        ordersToFetchCount = LOAD_MORE_INCREMENTER;
      } else {
        ordersToFetchCount = visibleOrders.length;
      }
  
      return orderActions.getPartialOrderSummaries(status, ordersToFetchCount, 0)
    }
    
  }
  /**
   * Filters the orders based on the order status
   * Returns an object who's indexes are order statuses and values are arrays of orders
   */
  const getSortedOrders = orders => {
    // Create an object who's indexes are order statuses and values are empty arrays to be filled with orders
    const ordersByStatus = Object.values(orderStatuses).reduce((acc, curr) => {
      acc[curr] = [];
      return acc;
    }, {});

    orders &&
      orders.forEach(order => {
        if(!ordersByStatus[order.status]) ordersByStatus[order.status] = [];
        ordersByStatus[order.status].push(order);
      });

    return ordersByStatus;
  };

  // Sets the active tab on click
  // Gets the order summaries corresponding to that tab
  const onTabSelect = tabKey => {
    setActiveTab(tabKey);
    if (!tableFilter) {
      // Don't fetch orders if the user is looking at a search result
      fetchOrdersForActiveTab(tabKey, itsFiltered)
    }
  }
  /**
   * Fetches orders based on the currently selected tab
   * @param {*} tabKey
   */
  const fetchOrdersForActiveTab = (tabKey, itsFiltered) => {
    if (tabKey === orderTableTabs.ONGOING && !itsFiltered) {
      ordersApi.getOrderSummaries({statuses: tableTabStatuses[orderTableTabs.ONGOING].join(',')})
        .then(response => {
          let orders = response.orders
          orders = orders.map(order => OrderSummaryToJSON(order))
          dispatch(OrderActionCreator.getAllOrdersSuccess(orders))          
        })
        .catch(() => dispatch(OrderActionCreator.getAllOrdersFailure()))
    } else {
      fetchLoadMoreOrders(tabKey)
    }
  }

  return (
    <div className="order-status-container">
      <div className="order-status-header">
        <OrderTableSearch
          orderActions={orderActions}
          activeTab={activeTab}
          setTableFilter={setTableFilter}
          setIsPolling={setIsPolling}
          fetchOrdersForActiveTab={fetchOrdersForActiveTab}
        />
        {/* <ExportOrdersDropdown exportSolarOrders={orderActions.exportSolarOrders} /> */}
      </div>
      <Tabs id="ordersTable" defaultActiveKey={'ongoing'} onSelect={onTabSelect}>
        <Tab eventKey="ongoing" title="Ongoing" style={{ paddingTop: '20px' }}>
          <MultiStatusTable
            ordersByStatus={ordersData}
            setIsPolling={setIsPolling}
            orgs={orgs}
          />
        </Tab>
        <Tab eventKey="completed" title="Delivered">
          <LoadMoreOrderTable
            orders={ordersData[orderStatuses.DELIVERED]}
            orgs={orgs}
            activeTab={orderTableTabs.COMPLETED}
            getMoreOrderSummaries={orderActions.getPartialOrderSummaries}
            loadMoreIncrementSize={LOAD_MORE_INCREMENTER}
          />
        </Tab>
        <Tab eventKey="pending" title="Pending">
          <LoadMoreOrderTable
            orders={ordersData[orderStatuses.STARTED]}
            orgs={orgs}
            activeTab={orderTableTabs.PENDING}
            getMoreOrderSummaries={orderActions.getPartialOrderSummaries}
            loadMoreIncrementSize={LOAD_MORE_INCREMENTER}
          />
        </Tab>
        <Tab eventKey="cancelled" title="Cancelled">
          <LoadMoreOrderTable
            orders={ordersData[orderStatuses.CANCELLED]}
            orgs={orgs}
            activeTab={orderTableTabs.CANCELLED}
            incrementLoadMoreNumberVisible={orderActions.incrementLoadMoreNumberVisible}
            getMoreOrderSummaries={orderActions.getPartialOrderSummaries}
            loadMoreIncrementSize={LOAD_MORE_INCREMENTER}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

// Only get the order values when they change in state
const getOrderSelector = createSelector(
  [state => (state.order && state.order.orders && Object.values(state.order.orders)) || []],
  orders => orders
);
const mapStateToProps = (state, props) => ({
  orders: getOrderSelector(state),
  metrics: state.order && state.order.metrics
});

const mapDispatchToProps = dispatch => {
  return {
    orderActions: bindActionCreators(OrderActionCreator, dispatch)
  };
};

const ConnectedOrderStatus = connect(mapStateToProps, mapDispatchToProps)(OrderStatus);

export default ConnectedOrderStatus;
