import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import OrderDatePicker from '../../../../../shared/OrderDatePicker';
import { dataCollectionStatus } from '../../../../util/constants';
import { ContactDisplay } from './shared';
import moment from 'moment';
import { getTsecsFromDate } from '../../../../util';
import { Typeahead } from 'react-bootstrap-typeahead';

/**
 * Edit View component for data collection
 * Contains logic for finding view data and render as well
 * @param {{} order - frontend object of selected order
 * @param {[]} orgs - list of orgs for org selection
 * @param {[]} contacts - list of contacts for order client org id
 */
const EditView = ({ order, orgs, contacts, handleSave, solarFarm }) => {
  const [siteContact, setSiteContact] = useState(
    contacts.find(c => c.uuid == order.site_contact_uuid)
  );

  /**
   * Returns a date picker for a date value on an order
   * @param {*} value - formatted as a date string 'MM/DD/YYYY'
   * @param {*} property - property on the order that the value corresponds to
   * @param {*} setFieldValue - handle to update the formik field value
   */
  const getDateField = (value, property, setFieldValue) => {
    let date = value ? moment.unix(value).format('MM/DD/YYYY') : null;
    return (
      <OrderDatePicker
        value={date}
        onUpdate={newValue => setFieldValue(property, getTsecsFromDate(newValue))}
      />
    );
  };

  /**
   * Gets a dropdown for the particular data collection
   * @param {*} value - The value of the dropdown
   * @param {*} property - the property on the order for the dropdown
   * @param {*} setFieldValue - handle to update the formik field value
   */
  const getValueset = (value, property, setFieldValue) => (
    <DropdownButton
      size="sm"
      title={dataCollectionStatus[value] || 'Select Data Collection Status'}
    >
      {Object.keys(dataCollectionStatus).map(p => (
        <Dropdown.Item
          value={dataCollectionStatus[p]}
          name={property}
          onSelect={() => {
            // eslint-disable-next-line no-undef
            mixpanel.track("qv.data_collection.status_updated", {
              user_id: sessionStorage.getItem("user_id") ?? "",
              order_id: order.id,
              solar_farm_id: solarFarm.id,
              new_status: dataCollectionStatus[p],
            });
            setFieldValue(property, p);
          }}
        >
          {dataCollectionStatus[p]}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
  // Needs to be put in state when ops needs to update it from the order edit
  const pilotContact = contacts.find(c => c.uuid == order.pilot_contact_uuid);

  return (
    <div style={{}}>
      <Formik
        initialValues={{
          turnkey_services: order.turnkey_services,
          requested_flight_date: order.requested_flight_date,
          estimated_flight_date: order.estimated_flight_date,
          data_collection_status: order.data_collection_status,
          access_code: solarFarm.access_code,
          site_contact_uuid: order.site_contact_uuid,
          data_collection_org_id: order.data_collection_org_id,
          pilot_contact_uuid: order.pilot_contact_uuid
        }}
        onSubmit={values => {
          handleSave(values);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <>
              <div style={{ paddingTop: '10px' }}>
                Turnkey Services: &nbsp;
                <Button
                  onClick={() => {
                    setFieldValue('turnkey_services', !values.turnkey_services);
                    setFieldValue('data_collection_status', !values.turnkey_services ? 1 : null);
                  }}
                >
                  {values.turnkey_services ? 'Yes' : 'No'}
                </Button>
              </div>
              <div style={{ paddingTop: '10px' }}>
                Requested Flight Date: &nbsp;
                {getDateField(values.requested_flight_date, 'requested_flight_date', setFieldValue)}
              </div>
              <div style={{ paddingTop: '10px' }}>
                Anticipated Flight Date: &nbsp;
                {getDateField(values.estimated_flight_date, 'estimated_flight_date', setFieldValue)}
              </div>
              <div style={{ paddingTop: '10px' }}>
                {getValueset(
                  values.data_collection_status,
                  'data_collection_status',
                  setFieldValue
                )}
              </div>
              <div style={{ paddingTop: '10px' }}>
                Site Access Code: &nbsp;
                <Form.Control
                  name="access_code"
                  as="textarea"
                  rows={1}
                  onChange={handleChange}
                  value={values.access_code}
                />
              </div>
              <div style={{ paddingTop: '10px' }}>
                <span>Data Collection Org: &nbsp;</span>
                {orgs && 
                  <Typeahead
                    defaultInputValue={
                      order.data_collection_org_id
                        ? orgs.find(org => org.id === order.data_collection_org_id).name
                        : ''
                    }
                    maxResults={5}
                    id="data_collection_org_id"
                    name="data_collection_org_id"
                    labelKey="name"
                    options={orgs}
                    placeholder="Search for an Org"
                    onChange={val => {
                      if (val && val.length > 0 && val[0].id) {
                        setFieldValue('data_collection_org_id', val[0].id);
                      }
                    }}
                    onInputChange={id => setFieldValue('data_collection_org_id', id)}
                  />
                }
                
                <div style={{ paddingTop: '10px' }}>
                  Site Contact:&nbsp;
                  {contacts &&
                    <Typeahead
                      defaultInputValue={
                        siteContact
                          ? siteContact.first_name === siteContact.last_name
                            ? `${siteContact.first_name} ${siteContact.company_name}`
                            : `${siteContact.first_name} ${siteContact.last_name} ${siteContact.company_name}`
                          : ''
                      }
                      name="site_contact_uuid"
                      id="site_contact_uuid"
                      options={contacts}
                      placeholder="Type to search..."
                      labelKey={contact =>
                        contact
                          ? contact.first_name === contact.last_name
                            ? `${contact.first_name} ${contact.company_name}`
                            : `${contact.first_name} ${contact.last_name} ${contact.company_name}`
                          : ''
                      }
                      onChange={val => {
                        val.length > 0 &&
                          setFieldValue('site_contact_uuid', val[0].uuid) &&
                          setSiteContact(val[0]);
                      }}
                    />
                  }
                  {siteContact && ContactDisplay(siteContact)}
                </div>
                {/* <div style={{ paddingTop: '10px' }}>
                  Pilot Contact:
                  {pilotContact && ContactDisplay(pilotContact)}
                </div> */}
                <Button onClick={handleSubmit}>Save</Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditView;
