export const GET_ALL_ORDERS = 'ordersReducer/FETCH_ORDERS';
export const GET_ALL_ORDERS_FAILURE = 'ordersReducer/GET_ALL_ORDERS_FAILURE';
export const GET_ALL_ORDERS_SUCCESS = 'ordersReducer/GET_ALL_ORDERS_SUCCESS';
export const SET_ITS_FILTERED = 'ordersReducer/SET_ITS_FILTERED';
export const UPDATE_ORDER_SUCCESS = 'ordersReducer/UPDATE_ORDER_SUCCESS';
export const GET_ALL_INSTRUCTIONS = 'ordersReducer/GET_ALL_INSTRUCTIONS';
export const GET_ALL_INSTRUCTIONS_SUCCESS = 'ordersReducer/GET_ALL_INSTRUCTIONS_SUCCESS';
export const GET_ALL_SERVICES = 'ordersReducer/GET_ALL_SERVICESS';
export const GET_ALL_SERVICES_SUCCESS = 'ordersReducer/GET_ALL_SERVICES_SUCCESS';
export const UPDATE_INSPECTION_ORDER_SUCCESS = 'ordersReducer/UPDATE_INSPECTION_ORDER_SUCCESS';
export const REMOVE_INSPECTION_ORDER_SUCCESS = 'ordersReducer/REMOVE_INSPECTION_ORDER_SUCCESS';
export const CREATE_ORDER_SUCCESS = 'ordersReducer/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'ordersReducer/CREATE_ORDER_FAILURE';
export const UPDATE_INSTRUCTION_SUCCESS = 'ordersReducer/UPDATE_INSTRUCTION_SUCCESS';
export const DELETE_ORDER_SUCCESS = 'ordersReducer/DELETE_ORDER_SUCCESS';
export const CREATE_UPLOAD_REQUEST_SUCCESS = 'ordersReducer/CREATE_UPLOAD_REQUEST_SUCCESS';
export const UPDATE_ACCESS_TOKEN_SUCCESS = 'ordersReducer/UPDATE_ACCESS_TOKEN_SUCCESS';
export const DELETE_ACCESS_TOKEN_SUCCESS = 'ordersReducer/DELETE_ACCESS_TOKEN_SUCCESS';
export const ADD_REQUEST_SUCCESS = 'ordersReducer/ADD_REQUEST_SUCCESS';
export const REMOVE_REQUEST_SUCCESS = 'ordersReducer/REMOVE_REQUEST_SUCCESS';
export const GET_ACCOUNTING_INFO_ITEMS_SUCCESS = 'ordersReducer/GET_ACCOUNTING_INFO_ITEMS_SUCCESS';
export const GET_ACCOUNTING_INFO_ITEMS_FAILURE = 'ordersReducer/GET_ACCOUNTING_INFO_ITEMS_FAILURE';
export const ADD_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS = 'ordersReducer/ADD_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS';
export const ADD_ORDER_ACCOUNTING_INFO_ITEM_FAILURE = 'ordersReducer/ADD_ORDER_ACCOUNTING_INFO_ITEM_FAILURE';
export const REMOVE_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS = 'ordersReducer/REMOVE_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS';
export const REMOVE_ORDER_ACCOUNTING_INFO_ITEM_FAILURE = 'ordersReducer/REMOVE_ORDER_ACCOUNTING_INFO_ITEM_FAILURE';
export const UPDATE_ORDER_FAILURE = 'ordersReducer/UPDATE_ORDER_FAILURE';
export const EXPORT_SOLAR_ORDERS_SUCCESS = 'ordersReducer/EXPORT_SOLAR_ORDERS_SUCCESS';
export const EXPORT_SOLAR_ORDERS_FAILURE = 'ordersReducer/EXPORT_SOLAR_ORDERS_FAILURE';
export const UPDATE_ORDER_STATUS = 'ordersReducer/UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_INSPECTION_FLIGHT_BOUNDARY = 'ordersReducer/UPDATE_ORDER_INSPECTION_FLIGHT_BOUNDARY';
export const UPDATE_ORDER_INSPECTION_FLIGHT_BOUNDARY_SUCCESS = 'ordersReducer/UPDATE_ORDER_INSPECTION_FLIGHT_BOUNDARY_SUCCESS';
export const SET_VISIBLE_ORDER_COUNT = 'ordersReducer/SET_VISIBLE_ORDER_COUNT';
export const GET_MORE_ORDERS_SUCCESS = 'ordersReducer/GET_MORE_ORDERS_SUCCESS';
export const GET_REPORT_PROVIDER_SUCCESS = 'ordersReducer/GET_REPORT_PROVIDER_SUCCESS';
export const GET_REPORT_PROVIDER_FAILURE = 'ordersReducer/GET_REPORT_PROVIDER_FAILURE';
export const GET_EVENTS_BY_ORDERID_SUCCESS = 'ordersReducer/GET_EVENTS_BY_ORDERID_SUCCESS';
export const LOADED_GET_EVENTS = 'ordersReducer/LOADED_GET_EVENTS';
export const GET_EVENTS_BY_ORDERID_FAILURE = 'ordersReducer/GET_EVENTS_BY_ORDERID_FAILURE';