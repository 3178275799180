import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import toastr from "toastr";

import EditView from "./views/EditView";
import ReadView from "./views/ReadView";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { bindActionCreators } from "redux";
import { OrderActionCreator } from "../../../../../actions/orderActions";

import { Contact } from "@raptormaps/raptor-models";
import RestApiV2 from "../../../../../api/RestApiV2";
import { dataCollectionStatus } from "../../../util/constants";

/**
 * WorkflowListItem - handles styling for list items
 * @param {*} bold - boolean for font weight value
 * @param {*} string - string for status display
 */
const WorkflowListItem = (bold, status) => {
  return <span style={{ fontWeight: 500, fontSize: "14px" }}>{status}</span>;
};

const DataCollectionTab = ({ order, orgs, orderActions }) => {
  const [contacts, setContacts] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [solarFarm, setSolarFarm] = useState({});
  const api = new RestApiV2();

  useEffect(() => {
    order.client_orgs &&
      order.client_orgs.map((org) => {
        getContacts(org.id);
      });
    getFarm(order.solar_farm_uuid);
  }, [order]);

  const getContacts = (orgId) => {
    api.get(`raptor_admin/orgs/${orgId}/contacts`).then((res) => {
      setContacts([
        ...contacts,
        ...res.contacts.map((c) => new Contact().deserialize(c)),
      ]);
    });
  };

  /**
   * Gets farm for given uuid
   * @param {*} solarFarmUuid
   */
  const getFarm = (solarFarmUuid) => {
    api.get(`solar_farms/${solarFarmUuid}`).then((res) => {
      setSolarFarm(res.solar_farm);
    });
  };
  /**
   * Function that handles saving the order to backend
   * Calls two functions and updates state
   * Also sets edit to false
   * @param {*} formValues form object passed in from formik
   */
  const handleSave = (formValues) => {
    updateOrderFromForm(formValues)
      .then(updateFarmFromForm(formValues))
      .then(setIsEditable(false))
      .catch(err => {
        setIsEditable(true);
        toastr.error(err);
      });
  };

  /**
   * Takes form values and returns api call promise
   * @param {*} formValues
   */
  const updateFarmFromForm = (formValues) => {
    const updatedFarm = {
      ...solarFarm,
      access_code: formValues.access_code,
    };
    return api.put(`solar_farms/${solarFarm.uuid}`, updatedFarm);
  };

  /**
   * Takes form values and return action call promise
   * @param {*} formValues
   */
  const updateOrderFromForm = (formValues) => {
    if(formValues.turnkey_services){
      if(!formValues.data_collection_org_id){
        return Promise.reject("Attempted save with turnkey services enabled - must select data collection org!")
      }
    } else {
      if(formValues.data_collection_org_id){
        return Promise.reject("Cannot save order with Turnkey enabled and a DCO selected!");
      }
    }

    const updatedOrder = {
      ...order,
      turnkey_services: formValues.turnkey_services,
      requested_flight_date: formValues.requested_flight_date,
      estimated_flight_date: formValues.estimated_flight_date,
      data_collection_status: formValues.data_collection_status,
      site_contact_uuid: formValues.site_contact_uuid,
      data_collection_org_id: formValues.data_collection_org_id ? formValues.data_collection_org_id : null,
      pilot_contact_uuid: formValues.pilot_contact_uuid,
    };
    return orderActions.updateOrder(updatedOrder);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", height: "600px" }}>
      <>
        {order.turnkey_services && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "15%",
              marginTop: "20px",
            }}
          >
            {Object.keys(dataCollectionStatus).map((k) =>
              WorkflowListItem(
                k == order.data_collection_status,
                dataCollectionStatus[k]
              )
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: `${order.turnkey_services ? "85%" : "100%"}`,
          }}
        >
          <FontAwesomeIcon
            style={{
              marginLeft: "auto",
              color: `${isEditable ? "orange" : "black"}`,
            }}
            icon={faEdit}
            onClick={() => {
              setIsEditable((isEditable) => !isEditable);
            }}
          />
          {isEditable ? (
            <EditView
              order={order}
              orgs={orgs}
              contacts={contacts}
              handleSave={handleSave}
              solarFarm={solarFarm}
            />
          ) : (
            <ReadView
              order={order}
              contacts={contacts}
              orgs={orgs}
              solarFarm={solarFarm}
            />
          )}
        </div>
      </>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  orgs: state.session.orgs || [],
});

const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(OrderActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataCollectionTab);
