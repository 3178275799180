import toastr from 'toastr';
import { moment } from '@raptormaps/raptor-lib';
import OrdersApiClient from '../apiClient/OrdersApiClient';
import EventsApiClient from '../apiClient/EventsApiClient';
import SolarInspectionsApiClient from '../apiClient/SolarInspectionsApiClient';
import { orderStatuses } from '../components/OrderStatus/util/constants';
import * as types from '../state/types/orderActionTypes';
import { mixPanelEventUpdated } from '../util/index';

import RestApiV2 from "../api/RestApiV2";


/**
 *Exportable object to create OrderActions
 */
export const OrderActionCreator = {
  updateSolarInspectionOnOrderSuccess: (solarInspection, orderUuid) => ({
    type: types.UPDATE_INSPECTION_ORDER_SUCCESS,
    solarInspection,
    orderUuid
  }),
  removeSolarInspectionOnOrderSuccess: (solarInspection, orderUuid) => ({
    type: types.REMOVE_INSPECTION_ORDER_SUCCESS,
    solarInspection,
    orderUuid
  }),
  getAllOrdersSuccess: orders => {
    return {
      type: types.GET_ALL_ORDERS_SUCCESS,
      orders
    };
  },
  setItsFiltered: flag => {
    return {
      type: types.SET_ITS_FILTERED,
      payload: { flag }
    };
  },
  getAllOrdersFailure: () => {
    return {
      type: types.GET_ALL_ORDERS_FAILURE
    };
  },
  updateOrderSuccess: order => ({
    type: types.UPDATE_ORDER_SUCCESS,
    order
  }),
  createUploadRequestSuccess: uploadRequest => ({
    type: types.CREATE_UPLOAD_REQUEST_SUCCESS,
    uploadRequest
  }),
  updateAccessTokenSuccess: (orderUuid, uploadRequest) => ({
    type: types.UPDATE_ACCESS_TOKEN_SUCCESS,
    orderUuid,
    uploadRequest
  }),
  deleteAccessTokenSuccess: uploadRequest => ({
    type: types.DELETE_ACCESS_TOKEN_SUCCESS,
    uploadRequest
  }),
  removeRequestFromOrderSuccess: (orderUuid, uploadRequestUuid) => ({
    type: types.REMOVE_REQUEST_SUCCESS,
    orderUuid,
    uploadRequestUuid
  }),
  getAccountingInfoItemsSuccess: accountingInfoItems => ({
    type: types.GET_ACCOUNTING_INFO_ITEMS_SUCCESS,
    accountingInfoItems
  }),
  getAccountingInfoItemsFailure: () => ({
    type: types.GET_ACCOUNTING_INFO_ITEMS_FAILURE
  }),
  /**
   * Removes an upload request from an order
   */
  removeRequestFromOrder: (orderUuid, uploadRequestUuid) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .delete(`orders/${orderUuid}/upload_requests/${uploadRequestUuid}`)
          .then(ret => {
            dispatch(
              OrderActionCreator.removeRequestFromOrderSuccess(orderUuid, uploadRequestUuid)
            );
            resolve();
          })
          .catch(err => {
            dispatch(
              OrderActionCreator.removeRequestFromOrderFailure(orderUuid, uploadRequestUuid)
            );
            toastr.error('Failed to remove request');
            reject();
          });
      });
    };
  },
  addRequestToOrderSuccess: order => ({
    type: types.ADD_REQUEST_SUCCESS,
    order
  }),
  /**
   * Adds an upload request to an order
   */
  addRequestToOrder: order => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`orders/${order.uuid}/upload_requests`, order)
          .then(ret => {
            dispatch(OrderActionCreator.addRequestToOrderSuccess(order));
            resolve();
          })
          .catch(err => {
            dispatch(OrderActionCreator.addRequestToOrderSuccess(order));
            reject();
          });
      });
    };
  },
  /**
   * Creates an upload request
   */
  createUploadRequest: (order, uploadRequestName) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`/orders/${order.uuid}/upload_requests`, { name: uploadRequestName })
          .then(ret => {
            dispatch(OrderActionCreator.addRequestToOrderSuccess(ret.order));
            resolve(ret.order);
          })
          .catch(err => {
            console.log(err);
            toastr.error('Failed to create upload request');
            reject();
          });
      });
    };
  },
  /**
   * Toggles the accessibility value for an access token
   */
  toggleAccessToken: (orderUuid, uploadRequestId) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`raptor_admin_upload_requests/${uploadRequestId}/toggle_active`, {})
          .then(ret => {
            dispatch(
              OrderActionCreator.updateAccessTokenSuccess(orderUuid, ret.upload_request)
            );
            resolve();
          })
          .catch(err => {
            toastr.error('Failed to toggle access lock');
            reject();
          });
      });
    };
  },
  updateOrderFailure: () => ({
    type: types.UPDATE_ORDER_FAILURE
  }),
  /**
   * Updates either the full contents of an order excluding status, or soley the order status
   */
  updateOrder: (order, statusUpdated = false) => {
    return function (dispatch) {
      // Verifying data_received_tsecs by status
      if (order?.status >=3 && !order?.data_received_tsecs) {
        order.data_received_tsecs = moment().unix();
      }

      // If status is updated, use the specific endpoint
      const url = statusUpdated ? `orders/${order.uuid}/status` : `orders/${order.uuid}`;
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .put(url, order)
          .then(res => {
            dispatch(OrderActionCreator.updateOrderSuccess(order));
            resolve(order);
            toastr.success('Order updated')
          })
          .catch(err => {
            reject(err);
            toastr.error('Failed to update order ' + err);
            dispatch(OrderActionCreator.updateOrderFailure());
          });
      });
    };
  },
  /**
   * Gets a fully populated, single order
   * @param {*} - orderUuid
   */
  getOrder: orderUuid => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`raptor_admin/orders/${orderUuid}`)
          .then(res => {
            let resJson = res;
            if (typeof resJson === "string") {
              resJson = resJson.replace(/NaN/g, 'null');
              resJson = JSON.parse(resJson);
            }
            dispatch(OrderActionCreator.updateOrderSuccess(resJson.order));
            resolve(res.order);
          })
          .catch(err => {
            reject(err);

            dispatch(OrderActionCreator.getOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Get orders specifically for dispatch view
   */
  getDispatchOrders: () => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`dispatch_orders`)
          .then(res => {
            dispatch(OrderActionCreator.getAllOrdersSuccess(res.orders));
            resolve(res);
          })
          .catch(err => {
            reject(err);

            dispatch(OrderActionCreator.getOrdersFailure());
            toastr.error(err);
          });
      });
    };
  },
  getAllServicesSuccess: services => ({
    type: types.GET_ALL_SERVICES_SUCCESS,
    services
  }),
  /**
   * Gets the list of all order services
   */
  getAllServices: (orderType = null) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get('services', orderType ? { order_type: orderType } : {})
          .then(res => {
            dispatch(OrderActionCreator.getAllServicesSuccess(res.services));
            resolve();
          })
          .catch(err => {
            dispatch(OrderActionCreator.getAllServicesFailure());
            toastr.error('Failed to get services');

            reject();
          });
      });
    };
  },
  getAllInstructionsSuccess: instructions => ({
    type: types.GET_ALL_INSTRUCTIONS_SUCCESS,
    instructions
  }),
  /**
   * Gets a list of all order instructions
   */
  getAllInstructions: () => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get('channel_instructions')
          .then(res => {
            dispatch(OrderActionCreator.getAllInstructionsSuccess(res.channel_instructions));
            resolve();
          })
          .catch(err => {
            toastr.error('Failed to get instructions');
            dispatch(OrderActionCreator.getAllInstructionsFailure());
            reject();
          });
      });
    };
  },
  /**
   * Updates a solar inspection order uuid to add/remove inspections from orders
   * api - api_update_inspection
   * @param solarInspection {SolarInspection}
   * @returns {function(*=, *=): Promise}
   */
  updateSolarInspectionForOrder: (solarInspection, orderUuid, farmName = null) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .put(`solar_inspections/${solarInspection.uuid}`, solarInspection)
          .then(res => {
            resolve(solarInspection);
            // If we have a farm name, we are adding an inspection to an order, if not we are removing an inspection from the order
            if (farmName) {
              // Add the farm name for the view
              const inspectionSummary = { ...solarInspection, farmName };
              // Modify the order state to add the inspection to the order in state if successful
              dispatch(
                OrderActionCreator.updateSolarInspectionOnOrderSuccess(inspectionSummary, orderUuid)
              );
            } else {
              // Modify the order state to remove the inspection to the order in state if successful
              dispatch(
                OrderActionCreator.removeSolarInspectionOnOrderSuccess(solarInspection, orderUuid)
              );
            }
          })
          .catch(err => {
            reject(err);
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Removes a specific service from an order
   */
  removeServiceFromOrder: (order, serviceUuid) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .delete(`orders/${order.uuid}/services/${serviceUuid}`)
          .then(res => {
            resolve(order);
            dispatch(OrderActionCreator.updateOrderSuccess(order));
          })
          .catch(err => {
            reject(err);

            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Removes an instruction from an order
   */
  removeInstructionFromOrder: (order, instructionUuid) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .delete(`orders/${order.uuid}/channel_instructions/${instructionUuid}`)
          .then(res => {
            resolve(order);
            dispatch(OrderActionCreator.updateOrderSuccess(order));
          })
          .catch(err => {
            reject(err);

            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Adds a service to an order
   */
  addServiceToOrder: order => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`orders/${order.uuid}/services`, order)
          .then(res => {
            dispatch(OrderActionCreator.updateOrderSuccess(order));
            resolve(order);
          })
          .catch(err => {
            reject(err);

            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Adds an instruction to an order
   */
  addInstructionToOrder: order => {
    // eslint-disable-next-line no-undef
    const orderCopy = _.cloneDeep(order);

    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`orders/${order.uuid}/channel_instructions`, orderCopy)
          .then(res => {
            resolve(order);
            dispatch(OrderActionCreator.updateOrderSuccess(order));
          })
          .catch(err => {
            reject(err);

            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  deleteOrderSuccess: orderUuid => ({
    type: types.DELETE_ORDER_SUCCESS,
    orderUuid
  }),
  updateInstructionSuccess: (orderUuid, instruction) => ({
    type: types.UPDATE_INSTRUCTION_SUCCESS,
    orderUuid,
    instruction
  }),
  /**
   * Updates a status on an instruction on an order
   */
  updateInstructionStatus: (orderUuid, instruction) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .put(`orders/${orderUuid}/channel_instructions/${instruction.uuid}/status`, instruction)
          .then(res => {
            dispatch(OrderActionCreator.updateInstructionSuccess(orderUuid, instruction));
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Deletes an order by uuid the updates the deleted order in redux state
   */
  deleteOrder: orderUuid => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .delete(`orders/${orderUuid}`)
          .then(res => {
            resolve();
            dispatch(OrderActionCreator.deleteOrderSuccess(orderUuid));
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Cancels an order by uuid the updates the deleted order in redux state
   */
  cancelOrder: orderUuid => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`orders/${orderUuid}/cancel`)
          .then(res => {
            resolve();
            dispatch(OrderActionCreator.deleteOrderSuccess(orderUuid));
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.updateOrderFailure());
            toastr.error(err);
          });
      });
    };
  },

  /**
   * Gets all accounting info items from the backend
   * python - api_get_all_accounting_info_items
   * @returns {function(*=, *=): Promise<unknown>}
   */
  getAccountingInfoItems: () => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`accounting_info_items`)
          .then(res => {
            resolve(res);
            dispatch(OrderActionCreator.getAccountingInfoItemsSuccess(res.accounting_info_items));
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.getAccountingInfoItemsFailure());
            toastr.error(err);
          });
      });
    };
  },

  addOrderAccountingInfoItemSuccess: order => ({
    type: types.ADD_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS,
    order
  }),

  addOrderAccountingInfoItemFailure: () => ({
    type: types.ADD_ORDER_ACCOUNTING_INFO_ITEM_FAILURE
  }),

  /**
   * Takes an order and adds it's accounting items
   * api - api_add_accounting_info_items
   * @param order
   * @returns {function(*=, *=): Promise<unknown>}
   */
  addOrderAccountingInfoItem: order => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .post(`orders/${order.uuid}/accounting_info_items`, order)
          .then(res => {
            resolve(res);
            dispatch(OrderActionCreator.addOrderAccountingInfoItemSuccess(order));
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.addOrderAccountingInfoItemFailure());
            toastr.error(err);
          });
      });
    };
  },

  removeOrderAccountingInfoItemSuccess: (orderUuid, accountingItemCode) => ({
    type: types.REMOVE_ORDER_ACCOUNTING_INFO_ITEM_SUCCESS,
    orderUuid,
    accountingItemCode
  }),
  removeOrderAccountingInfoItemFailure: () => ({
    type: types.REMOVE_ORDER_ACCOUNTING_INFO_ITEM_FAILURE
  }),

  /**
   * Removes an accounting info item from an order
   * api - api_remove_accounting_info_item
   * @param orderUuid
   * @param accountingItemCode
   * @returns {function(*=, *=): Promise<unknown>}
   */
  removeOrderAccountingInfoItem: (orderUuid, accountingItemCode) => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .delete(`orders/${orderUuid}/accounting_info_items/${accountingItemCode}`)
          .then(res => {
            resolve();
            dispatch(
              OrderActionCreator.removeOrderAccountingInfoItemSuccess(orderUuid, accountingItemCode)
            );
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.removeOrderAccountingInfoItemFailure());
            toastr.error(err);
          });
      });
    };
  },  
  exportSolarOrdersSuccess: () => ({
    type: types.EXPORT_SOLAR_ORDERS_SUCCESS
  }),

  exportSolarOrdersFailure: () => ({
    type: types.EXPORT_SOLAR_ORDERS_FAILURE
  }),

  /**
   * Action to export solar orders as a csv
   * @param statusGroup {string} - either 'ongoing' or 'completed'
   * @returns {function(*=, *=): Promise<unknown>}
   * api - api_raptor_admin_get_solar_orders_csv
   */
  exportSolarOrders: statusGroup => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const params = {
          status_group: statusGroup
        };
        const api = new RestApiV2();
        api
          .get(`raptor_admin/solar_orders/csv`, params)
          .then(res => {
            resolve();
            dispatch(OrderActionCreator.exportSolarOrdersSuccess());
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.exportSolarOrdersFailure());
            toastr.error(err);
          });
      });
    };
  },
  /**
   * Duplicates an order for rework
   * Defaults new order to submitted status
   */
  duplicateOrder: orderUuid => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const params = {
          new_status: orderStatuses.SUBMITTED,
          rework: true
        };
        const api = new RestApiV2();
        api
          .post(`orders/${orderUuid}/duplicate`, params)
          .then(res => {
            resolve();
          })
          .catch(err => {
            reject(err);
            toastr.error(err);
          });
      });
    }
  },
  /**
   * Updates an order's priority field
   * @param {*} order - order to update priority field
   */
  updatePriority: (order) => {
    return function (dispatch, getState) {

      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .put(`orders/${order.uuid}/priority`, order)
          .then(res => {
            dispatch(OrderActionCreator.updateOrderSuccess(order));
            resolve(order);
          })
          .catch(err => {
            reject(err);
            toastr.error("Failed to update order " + err);
            dispatch(OrderActionCreator.updateOrderFailure());
          });
      });
    };
  },
  updateInspectionFlightBoundarySuccess: (orderUuid, inspectionUuid, boundaryGeojson) => ({
    type: types.UPDATE_ORDER_INSPECTION_FLIGHT_BOUNDARY_SUCCESS,
    orderUuid,
    inspectionUuid,
    boundaryGeojson
  }),
  /**
   * Launches api call to update flight boundary
   * If successful updates the inspection flight boundary in the redux store
   * @param {*} orderUuid - order containing inspection who's flight boundary is updated
   * @param {*} inspectionUuid - inspection associated with flight boundary
   * @param {*} boundaryGeojson - new boundary geojson
   */
  updateInspectionFlightBoundary: (orderUuid, inspectionUuid, boundaryGeojson) => {
    return function (dispatch, getState) {
      const inspectionApi = new SolarInspectionsApiClient();
      inspectionApi.updateInspectionFlightBoundary(inspectionUuid, boundaryGeojson).then(
        () => dispatch(OrderActionCreator.updateInspectionFlightBoundarySuccess(orderUuid, inspectionUuid, boundaryGeojson))
      )
    }
  },
  /**
   * Gets order summaries for a specific status at an offset and limit
   * @param {*} status - order status to fetch
   * @param {*} limit - number of orders returned
   * @param {*} offset - number of orders to shift return value list from complete list
   */
  getPartialOrderSummaries: (status, limit, offset) => {
    return function (dispatch, getState) {
      const ordersApi = new OrdersApiClient();
      ordersApi.getPartialOrderSummaries(status, limit, offset)
        .then(
          (orders) => dispatch(OrderActionCreator.getMoreOrdersSuccess(orders))
        )
        .catch(
          () => dispatch(OrderActionCreator.getAllOrdersFailure())
        )
    }
  },
  /**
   * Gets order summaries based on a field/value pair search
   * Replaces all orders in redux state with search results
   * @param {*} field - field to search on
   * @param {*} value - value of field to search to
   */
  getFilteredOrderSummaries: (field, value) => {
    return function (dispatch, getState) {
      const ordersApi = new OrdersApiClient();
      ordersApi.getFilteredOrderSummaries(field, value)
        .then(
          (orders) => {
            dispatch(OrderActionCreator.getAllOrdersSuccess(orders))
            dispatch(OrderActionCreator.setItsFiltered(true))
          }
        )
        .catch(
          () => dispatch(OrderActionCreator.getAllOrdersFailure())
        )
    }
  },
  getMoreOrdersSuccess: orders => {
    return {
      type: types.GET_MORE_ORDERS_SUCCESS,
      orders
    };
  },
  incrementLoadMoreNumberVisible: (orderTabKey, numberToIncrement) => ({
    type: types.SET_VISIBLE_ORDER_COUNT,
    orderTabKey,
    numberToIncrement
  }),

  /**
   * Duplicates a construction order and it's associated inspections.
   * Sends a 'success' email if completed, otherwise an email with
   * the error message will be delivered.
   * @param {string} orderUuid - The uuid of the order to be duplicated
   * @param {string} newInspectionName - New name given to the duplicated order
   */
  duplicateConstructionOrder: (orderUuid, newInspectionName) => {
    return new Promise((resolve, reject) => {
      const payload = {
        new_inspection_name: newInspectionName
      };
      const api = new RestApiV2();
      api
        .post(`orders/${orderUuid}/duplicate_construction`, payload)
        .then(res => {
          resolve();
        })
        .catch(err => {
          reject(err);
          toastr.error(err);
        });
    });
  },
  getReportProviderSuccess: report_providers => ({
    type: types.GET_REPORT_PROVIDER_SUCCESS,
    report_providers
  }),
  getReportProviderFailure: () => ({
    type: types.GET_REPORT_PROVIDER_FAILURE
  }),
  loadedEvents: flag => ({
    type: types.LOADED_GET_EVENTS,
    payload: flag
  }),
  getEventsByOrderIdSuccess: events => ({
    type: types.GET_EVENTS_BY_ORDERID_SUCCESS,
    payload: { events }
  }),
  getEventsByOrderIdFailure: () => ({
    type: types.GET_EVENTS_BY_ORDERID_FAILURE
  }),
  getReportProviders: () => {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const api = new RestApiV2();
        api
          .get(`/report_providers`)
          .then(res => {
            resolve();
            dispatch(OrderActionCreator.getReportProviderSuccess(res.report_providers));
          })
          .catch(err => {
            reject(err);
            dispatch(OrderActionCreator.getReportProviderFailure());
            toastr.error(err);
          });
      });
    };
  },
  getEventsByOrderId: (order_id) => {
    return function (dispatch) {
      const api = new EventsApiClient()
      dispatch(OrderActionCreator.loadedEvents(false));
      api.getEventsByOrderId(order_id).then((event) => {
        dispatch(OrderActionCreator.getEventsByOrderIdSuccess(event));
        dispatch(OrderActionCreator.loadedEvents(true));
      }
      ).catch(() => {
        dispatch(OrderActionCreator.getEventsByOrderIdFailure());
        dispatch(OrderActionCreator.loadedEvents(true));
      })
    };
  },
    /**
   * Updates flight Scheduling dates
   */
    updateEventById: (orderId, event) => {
      return function (dispatch) {
        const api = new EventsApiClient()
        api.updateEventById(orderId, event).then(() => {
          dispatch(OrderActionCreator.getEventsByOrderIdSuccess([]))
          dispatch(OrderActionCreator.getEventsByOrderId(orderId));
        }
        ).catch(() => {}).finally(mixPanelEventUpdated(event, orderId));
      };
    },
    /**
   * Create flight Scheduling dates
   */
    createEventById: (orderId, event) => {
      return function (dispatch) {
        const api = new EventsApiClient()
        api.createEventById(orderId, event).then(() => {
          dispatch(OrderActionCreator.getEventsByOrderIdSuccess([]))
          dispatch(OrderActionCreator.getEventsByOrderId(orderId));
        }
        ).catch(() => {}).finally(mixPanelEventUpdated(event, orderId));
      };
    },
};
