import styled from 'styled-components';

export const Section = styled.div.attrs(() => ({
  className: 'Section'
}))`
  margin: 20px;
`;

export const TableSection = styled.div.attrs(() => ({
  className: 'TableSection'
}))`
  padding-bottom: 40px;
`;

export const Title = styled.h1.attrs(() => ({
  clasName: 'Title'
}))`
  text-align: center;
  font-weight: 500;
  margin: 20px 0;
  font-size: 26px;
`;

export const ModalOrgTitle = styled.div.attrs(() => ({
  className: 'ModalOrgTitle'
}))`
  font-size: 22px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.GlobalColor.rGrey1};
  border: solid 1px ${({ theme }) => theme.colors.GlobalColor.rBlack3};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.GlobalColor.rGrey5};
  padding: 4px 15px;
  cursor: not-allowed;
`;

export const CustomSelect = styled.select.attrs(() => ({
  className: 'ModalOrgSelect'
}))`
  font-size: 22px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.GlobalColor.rBlack2};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.GlobalColor.rGrey5};
  padding: 4px 15px;
  margin-left: 10px;
`;

export const ErrorText = styled.span.attrs(() => ({
  className: 'ErrorText'
}))`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.GlobalColor.rRed1};
  transition: all 1s;
`;
