export const instructionStatuses = {
  'Not started': 1,
  Complete: 0,
  'In progress': 2,
  'Assistance needed': 3
};
export const ORDER_PROPERTY_STATUS = 'status';

// Order statuses to be used as constants.
// Such as: if order.status == order_statuses.SUBMITTED
export const orderStatuses = {
  STARTED: 1,
  SUBMITTED: 2,
  SITE_SETUP: 100,
  DISPATCHED: 3,
  PROCESSED: 4,
  QC: 5,
  DELIVERED: 6,
  BILLED: 7,
  CANCELLED: 98,
  REWORK: 101
};

// TODO: remove when valuesets are handled at the database level. In the meantime, we have to update this as we change statuses
export const orderValuesets = {
  order_type: {
    comprehensive: 'Comprehensive',
    standard: 'Standard',
    overview: 'Overview',
    historical_digitization: 'Historical Digitization',
    cad: 'CAD',
    construction: 'Construction',
    site_digitization: "Site Digitization",
    wiring: 'Wiring',
    custom: 'Custom',
    perimeter: 'Perimeter',
    substation: 'Substation',
    interconnection: 'Point of Interconnection (POI)',
    instant: 'Instant',
    cracking: 'Cracking',
    vegetation: 'Vegetation',
    erosion: 'Erosion',
  },
  channel: {
    Spice: 'Spice',
    Spice2: 'Spice2',
    QCrew: 'QCrew',
    Zest: 'Zest'
  },
  priority: {
    0: 'None',
    1: 'Low',
    2: 'Medium',
    3: 'High'
  },
  contract_type: {
    PO: 'PO',
    POC: 'POC',
    MSA: 'MSA'
  },
  status: {
    [orderStatuses.STARTED]: 'Started',
    [orderStatuses.SUBMITTED]: 'Submitted',
    [orderStatuses.SITE_SETUP]: 'Site Setup',
    [orderStatuses.DISPATCHED]: 'Dispatched',
    [orderStatuses.PROCESSED]: 'Processed',
    [orderStatuses.DELIVERED]: 'Delivered',
    [orderStatuses.CANCELLED]: 'Cancelled',
    [orderStatuses.REWORK]: 'Rework'
  },
  editable_statuses: {
    [orderStatuses.STARTED]: 'Started',
    [orderStatuses.SUBMITTED]: 'Submitted',
    [orderStatuses.SITE_SETUP]: 'Site Setup',
    [orderStatuses.DISPATCHED]: 'Dispatched',
    [orderStatuses.PROCESSED]: 'Processed',
    [orderStatuses.DELIVERED]: 'Delivered'
  }
};

export const orderTableTabs = {
  ONGOING: 'ongoing',
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

export const tableTabStatuses = {
  [orderTableTabs.ONGOING]: [
    orderStatuses.SUBMITTED,
    orderStatuses.DISPATCHED,
    orderStatuses.PROCESSED,
    orderStatuses.QC,
    orderStatuses.SITE_SETUP
  ],
  [orderTableTabs.PENDING]: [orderStatuses.STARTED],
  [orderTableTabs.COMPLETED]: [orderStatuses.DELIVERED],
  [orderTableTabs.CANCELLED]: [orderStatuses.CANCELLED]
};

export const VALID_CLIENT_ORDER_STATUSES = [orderStatuses.DELIVERED];

export const VALID_CHANNEL_ORDER_STATUSES = [
  orderStatuses.DISPATCHED,
  orderStatuses.SITE_SETUP,
  orderStatuses.PROCESSED
];

export const workflowState = {
  1: {
    statusText: 'Order has been created',
    nextStatus: 2
  },
  2: {
    statusText: 'Customer has pressed submit on the order form.',
    nextStatus: [100, 3]
  },
  100: {
    statusText: 'Channel Orgs can work, but not all data is available to fully process the order.',
    nextStatus: 3
  },
  3: {
    statusText: 'Channel Orgs can work on the order',
    nextStatus: 4
  },
  4: {
    statusText: "This status means the inspection has been processed and is being QC'd",
    nextStatus: 6
  },
  6: {
    statusText: 'The order is available in the Client Org.',
    nextStatus: null
  }
};

// List to hold order of workflow statuses
export const workflowStatusOrder = [1, 2, 100, 3, 4, 6];

// List to hold unused/misc statuses
export const nonWorkflowStatusOrder = [5, 98, 7];

export const dataCollectionStatus = {
  1: 'New Request',
  2: 'Planning',
  3: 'Awaiting Flight',
  4: 'Hold',
  5: 'Uploading Data',
  6: 'Data Under Review',
  7: 'Check Fail',
  0: 'Complete'
};

export const defaultTableSort = {
  dataField: 'priority',
  order: 'desc'
};

export const historicalDigitization = 'Historical Digitization';
