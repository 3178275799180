import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createOrg } from '../actions/sessionActions';

import { Button } from '@raptormaps/button';

/**
 * Component for creating a new organization with a master user email
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateOrganizationComponent = props => {
  /**
   * Make api call to create the new org
   * @param e
   */
  const createOrganization = e => {
    e.stopPropagation();
    e.preventDefault();
    const orgName = e.target[0].value;
    const masterEmail = e.target[1].value.toLowerCase();

    props.sessionActions.createOrg(orgName, props.type, masterEmail).then(org => {
      props.onCreateSuccess && props.onCreateSuccess(org);
    });
  };

  let FieldGroup = ({ id, label, help, ...props }) => {
    return (
      <FormGroup style={{ width: '300px' }} controlId={id}>
        <FormLabel>{label}</FormLabel>
        <FormControl {...props} />
      </FormGroup>
    );
  };

  return (
    <Form onSubmit={createOrganization} {...props}>
      <FieldGroup
        id="formControlsOrganizationName"
        type="text"
        label="Organization Name"
        placeholder="Big Corp"
      />
      <FieldGroup
        id="formControlsEmail"
        type="text"
        label="Master Email"
        placeholder="name@company.com"
      />
      <Button type="submit">
        Create
      </Button>
    </Form>
  );
};

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators({ createOrg }, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(CreateOrganizationComponent);
